import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Link from '../components/LinkWrapper'
import i18n from '../locales/he.yaml'

const AboutQuery = graphql`
  query AboutQuery {
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const AboutTemplate = ({ data }) => {
  const { survival } = data

  return (
    <>
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='columns is-mobile is-multiline is-centered'>
              <div className='column is-full-mobile is-10-tablet is-6-desktop'>
                <h3>{i18n.about.title}</h3>
                <p>{i18n.about.description}</p>
              </div>
              <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen'>
                <GatsbyImage
                  image={getImage(survival)}
                  title={i18n.about.mainImage.title}
                  alt={i18n.about.mainImage.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(survival).width,
                    margin: '0 auto' // used to center the image
                  }}
                />
                <div className='is-flex justify-center'>
                  <p className='has-text-centered has-text-gold-black'>
                    {i18n.about.mainImage.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <Link to='/' className='button is-nude is-outlined'>
              {i18n.about.goBack}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

const Academy = ({ location: { pathname } }) => {
  const data = useStaticQuery(AboutQuery)
  return (
    <Layout>
      <Seo
        metadata={i18n.about.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.academyHero),
          alt: i18n.about.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [{ name: i18n.header.about, path: '/about/' }]
        }}
      />
      <AboutTemplate pathname={pathname} data={data} />
    </Layout>
  )
}

export default Academy
